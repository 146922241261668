
// import axios from 'axios'
import CategorySlide from '../components/CategorySlide'
import Slides from '../components/Slides'
// import Sponsors from '../components/Sponsors'
import ContactForm from '../components/ContactForm'
// import SliderTest from '../components/SliderTest'
export default {
  auth: false,
  components: {
    CategorySlide,
    // Sponsors,
    ContactForm,
    // SliderTest,
    Slides
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.$nuxt.$loading.start()
    //   setTimeout(() => this.$nuxt.$loading.finish(), 1000)
    // })
  }
}
